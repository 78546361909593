import {Link} from "react-router-dom";
import {Button} from "react-bootstrap";
import "./gallery-menu.css";

interface IMenuOption {
    to: string
    value: string
}

interface IGalleryMenuProps {
    title: string
    inlineOptions?: boolean
    options: IMenuOption[]
    activeOption?: (option: IMenuOption) => boolean
}

const GalleryMenu = (props: IGalleryMenuProps) => {
    const classNameSplit: string[] = ["gallery-menu"];
    if (props.inlineOptions) classNameSplit.push("inline");

    const isActive = (option: IMenuOption): boolean => {
        if (props.activeOption !== undefined) {
            return props.activeOption(option);
        }

        return false;
    };

    return (
        <div className={classNameSplit.join(" ")}>
            <div className={"title"}>
                {props.title}
            </div>
            <div className={"links"}>
                {props.options.map((option, index) => {
                    return (
                        <MenuLink
                            to={option.to}
                            label={option.value}
                            isActive={isActive(option)}
                            key={index}
                        />
                    );
                })}
            </div>
        </div>
    );
};

interface IMenuLinkProps {
    to: string
    label: string
    isActive: boolean
}

const MenuLink = (props: IMenuLinkProps) => {
    return (
        <div className={"menu-link"}>
            <Link to={props.to}>
                <Button variant={props.isActive ? "dark" : "outline-dark"} size={"sm"}>
                    {props.label}
                </Button>
            </Link>
        </div>
    );
};

export default GalleryMenu;