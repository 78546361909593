import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import App from './App';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import PageHome from "./pages/PageHome";
import PageNotFound from "./pages/PageNotFound";
import PageAboutMe from "./pages/PageAboutMe";
import PageArtwork from "./pages/PageArtwork";
import PageGallery from "./pages/PageGallery";

const router = createBrowserRouter([
    {
        path: "/",
        element: <App/>,
        errorElement: <PageNotFound/>,
        children: [
            {
                path: "/",
                element: <PageHome/>
            },
            {
                path: "/digital-drawings",
                element: <PageGallery context={"digital-art"}/>
            },
            {
                path: "/digital-drawings/:id",
                element: <PageArtwork/>
            },
            {
                path: "/about-me",
                element: <PageAboutMe/>
            }
        ]
    }
]);

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <RouterProvider router={router}/>
    </React.StrictMode>
);