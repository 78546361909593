import {Col, Row} from "react-bootstrap";
import {useLocation, useSearchParams} from "react-router-dom";
import GalleryMenu from "../compos/gallery-menu/GalleryMenu";
import Gallery from "../compos/gallery/Gallery";
import IStaticFile from "../IStaticFile";
import {useEffect, useState} from "react";
import axios from "axios";

interface IProps {
    context: "digital-art"
}

const PageGallery = (props: IProps) => {
    const loc = useLocation();
    const [searchParams] = useSearchParams();

    const [artworks, setArtworks] = useState<IStaticFile[]>([]);

    const getUrl = () => {
        const baseUrl = 'https://api.static.fednuzzo.it/img/digital-art/artworks';
        if (!searchParams.has('year')) return baseUrl;
        const year = searchParams.get('year');
        if (year === '') return baseUrl;

        return baseUrl + `?year=${year}`;
    };

    const fetchArtworks = () => {
        const url: string = getUrl();
        axios.get(url)
            .then(response => {
                const foundArtworks: IStaticFile[] = response.data.artworks as IStaticFile[];
                setArtworks(foundArtworks);
            });
    };

    useEffect(() => {
        fetchArtworks();
    }, []);

    useEffect(() => {
        fetchArtworks();
    }, [searchParams]);

    return (
        <Row className={"page-gallery"}>
            <Col xxl={3} xl={3} lg={3} className={"page-side"}>
                <GalleryArchivesMenu/>
            </Col>
            <Col xxl={9} xl={9} lg={9} className={"page-body"}>
                <Gallery images={artworks} path={loc.pathname}/>
            </Col>
        </Row>
    );
};

const GalleryArchivesMenu = () => {
    const [searchParams] = useSearchParams();
    const [pubYears, setPubYears] = useState<number[]>([]);

    const fetchPubYears = () => {
        const url: string = 'https://api.static.fednuzzo.it/img/digital-art/pub-years';
        axios.get(url)
            .then(response => {
                const foundPubYears: number[] = response.data.years as number[];
                foundPubYears.push(0);
                setPubYears(foundPubYears);
            });
    }

    useEffect(() => {
        fetchPubYears();
    }, []);

    return (
        <GalleryMenu
            title={"Archives"} inlineOptions={true}
            activeOption={(option) => {
                if (option.value === "Latest") {
                    return searchParams.size === 0;
                }

                return option.value === searchParams.get('year');
            }}
            options={
                pubYears
                    .map((value) => {
                        if (value === 0) {
                            return {
                                value: "Latest",
                                to: "/digital-drawings"
                            }
                        }

                        return {
                            value: value.toString(),
                            to: "/digital-drawings?year=" + value
                        };
                    })
            }
        />
    );
}

const GalleryTagsMenu = () => {
    const [searchParams] = useSearchParams();

    return (
        <GalleryMenu
            title={"Tags"} inlineOptions={true}
            activeOption={(option) => {
                return option.value === '#' + searchParams.get('tag');
            }}
            options={
                ["anime", "actor"].map((value) => {
                    return {
                        value: "#" + value,
                        to: "/digital-drawings?tag=" + value
                    };
                })
            }
        />
    );
}

export default PageGallery;