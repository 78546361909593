import {Col, Image, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import IStaticFile, {getStaticFileThumbHref} from "../../IStaticFile";
import Cell from "../Cell";
import "./gallery.css";

interface IGalleryProps {
    images: IStaticFile[]
    path: string
}

const Gallery = (props: IGalleryProps) => {
    return (
        <Cell className={"gallery"}>
            <Row className={"grid"}>
                {props.images.map((currImage, index) => {
                        return (
                            <GalleryImage
                                file={currImage}
                                path={props.path}
                                key={index}
                            />
                        );
                    }
                )}
            </Row>
        </Cell>
    );
};

interface IGalleryImageCardProps {
    file: IStaticFile
    path: string
}

const GalleryImage = (props: IGalleryImageCardProps) => {
    return (
        <Col xxl={3} xl={3} lg={4} md={6} sm={6} xs={12}>
            <Link to={`${props.path}/${props.file.id}`}>
                <div className={"gallery-image"}>
                    <Image
                        src={getStaticFileThumbHref(props.file)}
                        fluid
                    />
                    <div className={"footer"}>
                        <div className={"title"}>{props.file.title}</div>
                        <div className={"year"}>{props.file.pub_year}</div>
                    </div>
                </div>
            </Link>
        </Col>
    );
};

export default Gallery;