import {Image} from "react-bootstrap";
import profile01 from "../media/profile-01.jpeg";
import Cell from "../compos/Cell";
import React from "react";

const PageAboutMe = () => {
    const classNameSplit: string[] = [
        "offset-xxl-2", "offset-xl-2", "offset-lg-2"
    ];

    return (
        <Cell xxl={8} xl={8} lg={8} md={12} sm={12} xs={12} className={classNameSplit.join(" ")}>
            <Paragraph header={"Passionate about creativity"}>
                My name is Federico.
                I was born on September 7th in 1988, at Taranto (a town in the South of Italy).
                My family had some issues and those led me to some kind of sensitivity and difficulties
                at expressing myself. I began to draw almost everywhere, at any time. That was my first
                approach to creativity as escape from reality.
            </Paragraph>
            <Paragraph>
                <Image src={profile01} width={200} roundedCircle/>
            </Paragraph>
            <Paragraph>
                Despite to my strong bound with humanistic subjects, I chose to follow a scientific
                scholastic route. I was fascinated by math and science in general, and I didn't like
                the idea to build a profession over arts and possibly get tired of drawing. I quickly
                discovered a true talent about computers and IT. My school didn't provided me a consistent
                learning course, so I learned on my own.
            </Paragraph>
            <Paragraph>
                In my life I moved and lived in several towns. I had issues in socializing and developing
                human connections. Depression hit me hard, followed by a strong eating disorder. I still
                struggle with these two monsters. When I had to find a job, software developing was the only
                real skill I was able to put on the market, and I became a professional developer. Today I'm
                an affirmed software architect, teacher and team leader.
            </Paragraph>
            <Paragraph>
                Due to my issues, art and creativity is still a big slice of my life. Growing up I discovered
                that I do enjoy not only drawing, but any form of creativity, and I often feel the need to
                express it in many ways. This makes me not so much consistent, but still very passionate.
            </Paragraph>
            <Paragraph>
                Today, I use my creativity in drawing and painting, in music playing, in software developing
                (occasionally including videogames), writing short and long novels, and exploring new activities
                and involving in new projects. I do not always end everything I do... But it's a start.
            </Paragraph>
            <Paragraph>
                <i>Federico Nuzzo</i>
            </Paragraph>
        </Cell>
    );
};

interface IParagraphProps {
    header?: string
    children: React.ReactNode
}

const Paragraph = (props: IParagraphProps) => {
    return (
        <Cell className={"typography"}>
            <h5>{props.header}</h5>
            <p>{props.children}</p>
        </Cell>
    );
};

export default PageAboutMe;