import "./top-menu.css";
import logo from "../media/logo.png";
import {Col, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFacebook, faGithub, faInstagram, faLinkedin, faPatreon, faTwitter} from "@fortawesome/free-brands-svg-icons";
import {faSuitcase} from "@fortawesome/free-solid-svg-icons";
import {IconDefinition} from "@fortawesome/fontawesome-svg-core";
import {Link, useLocation} from "react-router-dom";

const TopMenu = () => {
    return (
        <Row className={"top-menu"}>
            <Col>
                <LogoRow/>
                <SocialsRow/>
                <LinksRow/>
            </Col>
        </Row>
    );
};

const LogoRow = () => {
    return (
        <Row className={"logo-row"}>
            <Col>
                <Logo/>
            </Col>
        </Row>
    );
};

const Logo = () => {
    return (
        <div className={"logo"}>
            <img src={logo} width={250} alt={"Firma"}/>
            <div>Software architect</div>
            <div>Writer, artist & creative</div>
        </div>
    );
};

const SocialsRow = () => {
    return (
        <Row className={"socials-row text-center"}>
            <Col>
                <SocialLink
                    href={"https://twitter.com/fednuzzo"}
                    icon={faTwitter} color={"#00ACEE"}
                    title={"Twitter"}
                />
                <SocialLink
                    href={"https://www.facebook.com/federico.nuzzo.56"}
                    icon={faFacebook} color={"#3B5998"}
                    title={"Facebook"}
                />
                <SocialLink
                    href={"https://www.instagram.com/fednuzzo/"}
                    icon={faInstagram} color={"#C13584"}
                    title={"Instagram"}
                />
                <SocialLink
                    href={"https://www.linkedin.com/in/federico-nuzzo-8b07baa0/"}
                    icon={faLinkedin} color={"#0E76A8"}
                    title={"LinkedIn"}
                />
                <SocialLink
                    href={"https://github.com/fednuzzo"}
                    icon={faGithub} color={"#000000"}
                    title={"GitHub"}
                />
                <SocialLink
                    href={"https://www.patreon.com/fednuzzo"}
                    icon={faPatreon} color={"#f1465a"}
                    title={"Patreon"}
                />
                <SocialLink
                    href={"https://it.fiverr.com/fed_nuzzo"}
                    icon={faSuitcase} color={"#1DBF73"}
                    title={"Fiverr"}
                />
            </Col>
        </Row>
    );
};

interface ISocialLinkProps {
    href: string
    icon: IconDefinition
    color: string
    title?: string
}

const SocialLink = (props: ISocialLinkProps) => {
    return (
        <a href={props.href} target={"_blank"} rel={"noreferrer"}>
            <FontAwesomeIcon
                icon={props.icon}
                color={props.color}
                title={props.title}
            />
        </a>
    );
};

const LinksRow = () => {
    return (
        <Row className={"links-row text-center"}>
            <Col>
                <TopMenuLink label={"Home"} to={"/"}/>
                <TopMenuLink label={"Digital drawings"} to={"/digital-drawings"}/>
                <TopMenuLink label={"About me"} to={"/about-me"}/>
            </Col>
        </Row>
    );
};

interface ILinkProps {
    label: string
    to: string
}

const TopMenuLink = (props: ILinkProps) => {
    const loc = useLocation();

    const classNameSplit: string[] = ["link"];
    if (props.to === loc.pathname) classNameSplit.push("active");

    return (
        <Link to={props.to}>
            <div className={classNameSplit.join(" ")}>
                {props.label}
            </div>
        </Link>
    );
}


export default TopMenu;