import {Image} from "react-bootstrap";
import homePicture from "../media/home.jpg";
import Cell from "../compos/Cell";

const PageHome = () => {
    return (
        <Cell
            xxl={{span: 8, offset: 2}}
            xl={{span: 8, offset: 2}}
            lg={{span: 8, offset: 2}}
        >
            <Image src={homePicture} fluid/>
        </Cell>
    );
};

export default PageHome;