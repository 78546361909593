import {Button, Image, Spinner} from "react-bootstrap";
import IStaticFile, {getStaticFileHref} from "../IStaticFile";
import {useEffect, useState} from "react";
import axios from "axios";
import Cell from "../compos/Cell";
import {useNavigate, useParams} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";

const PageArtwork = () => {
    const {id} = useParams();
    const [artwork, setArtwork] = useState<IStaticFile | undefined>(undefined);

    const fetchArtwork = () => {
        axios.get(`https://api.static.fednuzzo.it/img/digital-art/artworks/${id}`)
            .then((response) => {
                const foundFile: IStaticFile = response.data.artwork as IStaticFile;
                setArtwork(foundFile);
            });
    };

    useEffect(() => {
        fetchArtwork();
    }, []);

    return (
        <Cell>
            {artwork === undefined && (
                <Cell className={"text-center"}>
                    <Spinner/>
                </Cell>
            )}
            {artwork !== undefined && (
                <>
                    <Cell className={"text-center"}>
                        <h2>{artwork.title} ({artwork.pub_year})</h2>
                        <p>
                            Made with <strong>{artwork.gen_software}</strong> on <strong>{artwork.gen_device}</strong>.
                        </p>
                        <BackButton/>
                    </Cell>
                    <Cell className={"text-center"}>
                        <Image src={getStaticFileHref(artwork)} fluid/>
                    </Cell>
                    <Cell className={"text-center"}>
                        <BackButton/>
                    </Cell>
                </>
            )}
        </Cell>
    );
};

const BackButton = () => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate(-1);
    };

    return (
        <Button
            variant={"outline-dark"}
            size={"sm"} className={"m-2"}
            onClick={() => {
                handleClick();
            }}
        >
            <FontAwesomeIcon icon={faArrowLeft}/>
            <span className={"ps-1"}>Back to gallery</span>
        </Button>
    );
};

export default PageArtwork;