import React from 'react';
import TopMenu from "./compos/TopMenu";
import {Outlet} from "react-router-dom";
import {Col, Container, Row} from "react-bootstrap";
import "./app.css";

const App = () => {
    return (
        <Container>
            <TopMenu/>
            <Outlet/>
            <CopyrightNote/>
        </Container>
    );
};

const CopyrightNote = () => {
    const curr: Date = new Date();
    const year: number = curr.getFullYear();

    return (
        <Row className={"copyright-note"}>
            <Col>
                &copy; 2017-{year} Federico Nuzzo. All rights reserved.
            </Col>
        </Row>
    );
};

export default App;
