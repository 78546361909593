import {Col, Row} from "react-bootstrap";

type NumberAttr = number | '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10' | '11' | '12';
type ColSize = boolean | 'auto' | NumberAttr;
type ColOrderNumber = number | '1' | '2' | '3' | '4' | '5';
type ColOrder = ColOrderNumber | 'first' | 'last';
type ColSpec = ColSize | {
    span?: ColSize;
    offset?: NumberAttr;
    order?: ColOrder;
};

interface ICellProps {
    children?: React.ReactNode
    className?: string
    xxl?: ColSpec
    xl?: ColSpec
    lg?: ColSpec
    md?: ColSpec
    sm?: ColSpec
    xs?: ColSpec
}

const Cell = (props: ICellProps) => {
    return (
        <Row>
            <Col
                xxl={props.xxl} xl={props.xl} lg={props.lg} md={props.md} sm={props.sm} xs={props.xs}
                className={props.className}
            >
                {props.children}
            </Col>
        </Row>
    );
};

export default Cell;