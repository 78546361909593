interface IStaticFile {
    id: number
    file_context: string
    title: string
    descr?: string
    tags?: string
    folder: string
    filename: string
    extension: string
    gen_device?: string
    gen_software?: string
    pub_year: number
    created_at: string
    updated_at: string
}

export const getStaticBaseUrl = () => {
    const protocol: string = "https";
    const baseUrl: string = "static.fednuzzo.it";
    return `${protocol}://${baseUrl}`;
};

export const getStaticFileHref = (stFi: IStaticFile) => {
    const baseUrl: string = getStaticBaseUrl();
    return `${baseUrl}/${stFi.folder}/${stFi.filename}.${stFi.extension}`
};

export const getStaticFileThumbHref = (stFi: IStaticFile) => {
    const baseUrl: string = getStaticBaseUrl();
    return `${baseUrl}/${stFi.folder}/${stFi.filename}.thumb.${stFi.extension}`
};

export default IStaticFile;